import { Col, Container, Row } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'

import AnimatedHeading from 'components/animated-heading'
import AnimationContainer from 'components/animation-container'
import React from 'react'

class ServicesOne extends React.Component {

    shouldComponentUpdate() {
        return false
    }
      
    render() {
        const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            background-image: url(${this.props.background.childImageSharp.fluid.src});
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `

        const ServiceContainer = styled.div`
            background-color: rgba(0,0,0,.9);
            padding: 100px 0;
        `
        const gradientAnimation = keyframes`
            0% {
                background-position: 15% 0%;
            }
            50% {
                background-position: 85% 100%;
            }
            100% {
                background-position: 15% 0%;
            }
        `

        const ColorAnimation = keyframes`
            0%  {background: #04e5e5;}
            10% {background: #f37055;}
            20% {background: #ef4e7b;}
            30% {background: #a166ab;}
            40% {background: #5073b8;}
            50% {background: #04e5e5;}
            60% {background: #07b39b;}
            70% {background: #6fba82;}
            80% {background: #5073b8;}
            90% {background: #1098ad;}
            100% {background: #f37055;}
        `

        const AnimatedShadow = keyframes`
            0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
            20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
            30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
            40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
            70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
            80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
            100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        `
        const ServiceElement = styled.div`
            margin-bottom: 20px;
            text-align: center;
            padding: 40px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #000;
                animation: ${AnimatedShadow} 10s infinite alternate;
                transform: translateY(-10px);
            }
        `
         const ServiceHeading = styled.h4`
            font-size: 30px;
            font-weight: 500;
            font-family: Teko;
            color: #fff;
        `
        const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 10px;
            margin: auto;
         `
        const ServiceIcon = styled.div`
            width: 120px;
            height: 120px;
            margin: 0 auto 25px auto;
            background-color: #fff;
            border-radius: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
            background-size: 300% 300%;
            animation: ${gradientAnimation} 5s ease-in-out infinite;
            img {
                max-height: 70px;
            }
        `

        const ServiceList = styled.ul`
            padding: 0;
            margin: 11px 0 0 0;
        `

        const ServiceListElement = styled.li`
            list-style: none;
            color: #c5c5c5;
            font-weight: 300;
            font-size: 14px;
            margin: 5px 0px;
            &::before {
                display: inline-block;
                content: '';
                border-radius: 2.5px;
                height: 5px;
                width: 5px;
                margin-right: 10px;
                position: relative;
                bottom: 2.5px;
                animation: ${ColorAnimation} 10s infinite alternate;
            }
        `

        return(
            <Section id="services">
                <ServiceContainer>
                    <Container>
                        <AnimatedHeading text="What I offer" />
                        <Row>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInLeft" delay={200}>
                                        <ServiceElement>
                                            <ServiceIcon>
                                                    <img src={this.props.development.childImageSharp.fluid.src} alt="COMPETENCY IN DEBUGGING CODE AT A HARDWARE LEVEL" />
                                            </ServiceIcon>
                                            <ServiceHeading>
                                            COMPETENCY IN DEBUGGING CODE AT A HARDWARE LEVEL
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            {/* <ServiceList>
                                                <ServiceListElement>
                                                    Lorem ipsum dolor sit amet
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    In vitae ultricies lacus vitae
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Pellentesque blandit libero
                                                </ServiceListElement>
                                            </ServiceList> */}
                                        </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInDown"  delay={400}>
                                    <ServiceElement>
                                        <ServiceIcon>
                                                <img src={this.props.application.childImageSharp.fluid.src} alt="EXPERIENCE OF THE FULL DEVELOPMENT LIFECYCLE" />
                                        </ServiceIcon>
                                        <ServiceHeading>
                                        EXPERIENCE OF THE FULL DEVELOPMENT LIFECYCLE
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        {/* <ServiceList>
                                            <ServiceListElement>
                                                Lorem ipsum dolor sit amet
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                In vitae ultricies lacus vitae
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                Pellentesque blandit libero
                                            </ServiceListElement>
                                        </ServiceList> */}
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInRight" delay={600}>
                                    <ServiceElement>
                                        <ServiceIcon>
                                                <img src={this.props.promo.childImageSharp.fluid.src} alt="BUILDING ON TOP OF EXISTING SOLUTIONS" />
                                        </ServiceIcon>
                                        <ServiceHeading>
                                        BUILDING ON TOP OF EXISTING SOLUTIONS
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                       {/*  <ServiceList>
                                            <ServiceListElement>
                                                Lorem ipsum dolor sit amet
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                In vitae ultricies lacus vitae
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                Pellentesque blandit libero
                                            </ServiceListElement>
                                        </ServiceList> */}
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInLeft" delay={800}>
                                    <ServiceElement>
                                        <ServiceIcon>
                                                <img src={this.props.threedmodeling.childImageSharp.fluid.src} alt="ABLE TO CONVEY COMPLEX IDEAS CLEARLY" />
                                        </ServiceIcon>
                                        <ServiceHeading>
                                        ABLE TO CONVEY COMPLEX IDEAS CLEARLY
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        {/* <ServiceList>
                                            <ServiceListElement>
                                                Lorem ipsum dolor sit amet
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                In vitae ultricies lacus vitae
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                Pellentesque blandit libero
                                            </ServiceListElement>
                                        </ServiceList> */}
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInUp" delay={1000}>
                                    <ServiceElement>
                                        <ServiceIcon>
                                                <img src={this.props.setting.childImageSharp.fluid.src} alt="STRONG CODING SKILLS" />
                                        </ServiceIcon>
                                        <ServiceHeading>
                                        STRONG CODING SKILLS
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        {/* <ServiceList>
                                            <ServiceListElement>
                                                Lorem ipsum dolor sit amet
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                In vitae ultricies lacus vitae
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                Pellentesque blandit libero
                                            </ServiceListElement>
                                        </ServiceList> */}
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                    <AnimationContainer animation="fadeInRight" delay={1200}>
                                        <ServiceElement>
                                            <ServiceIcon>
                                                    <img src={this.props.computer.childImageSharp.fluid.src} alt="DRIVING TO CLOSURE ANY SYSTEM INTEGRATION ISSUES" />
                                            </ServiceIcon>
                                            <ServiceHeading>
                                            DRIVING TO CLOSURE ANY SYSTEM INTEGRATION ISSUES
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            {/* <ServiceList>
                                                <ServiceListElement>
                                                    Lorem ipsum dolor sit amet
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    In vitae ultricies lacus vitae
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Pellentesque blandit libero
                                                </ServiceListElement>
                                            </ServiceList> */}
                                        </ServiceElement>
                                    </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                    <AnimationContainer animation="fadeInRight" delay={1200}>
                                        <ServiceElement>
                                            <ServiceIcon>
                                                    <img src={this.props.agile.childImageSharp.fluid.src} alt="KNOWLEDGE OF AGILE DEVELOPMENT METHODOLOGIES" />
                                            </ServiceIcon>
                                            <ServiceHeading>
                                            KNOWLEDGE OF AGILE DEVELOPMENT METHODOLOGIES
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            {/* <ServiceList>
                                                <ServiceListElement>
                                                    Lorem ipsum dolor sit amet
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    In vitae ultricies lacus vitae
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Pellentesque blandit libero
                                                </ServiceListElement>
                                            </ServiceList> */}
                                        </ServiceElement>
                                    </AnimationContainer>
                            </Col>

                            <Col md={4}>
                                    <AnimationContainer animation="fadeInRight" delay={1200}>
                                        <ServiceElement>
                                            <ServiceIcon>
                                                    <img src={this.props.webdevelopment.childImageSharp.fluid.src} alt="WILLING TO TAKE A LEADERSHIP ROLE IN ANY PROJECT" />
                                            </ServiceIcon>
                                            <ServiceHeading>
                                            WILLING TO TAKE A LEADERSHIP ROLE IN ANY PROJECT
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            {/* <ServiceList>
                                                <ServiceListElement>
                                                    Lorem ipsum dolor sit amet
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    In vitae ultricies lacus vitae
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Pellentesque blandit libero
                                                </ServiceListElement>
                                            </ServiceList> */}
                                        </ServiceElement>
                                    </AnimationContainer>
                            </Col>

                            <Col md={4}>
                                    <AnimationContainer animation="fadeInRight" delay={1200}>
                                        <ServiceElement>
                                            <ServiceIcon>
                                                    <img src={this.props.code.childImageSharp.fluid.src} alt="PROVIDING TECHNICAL LEADERSHIP TO A TEAM OF DEVELOPERS" />
                                            </ServiceIcon>
                                            <ServiceHeading>
                                            PROVIDING TECHNICAL LEADERSHIP TO A TEAM OF DEVELOPERS
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            {/* <ServiceList>
                                                <ServiceListElement>
                                                    Lorem ipsum dolor sit amet
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    In vitae ultricies lacus vitae
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Pellentesque blandit libero
                                                </ServiceListElement>
                                            </ServiceList> */}
                                        </ServiceElement>
                                    </AnimationContainer>
                            </Col>

                            
                        </Row>
                    </Container>
                </ServiceContainer>
            </Section>
        )
    }

}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        background: file(relativePath: {eq: "bg222.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        development: file(relativePath: {eq: "icons/development.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        application: file(relativePath: {eq: "icons/application.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        promo: file(relativePath: {eq: "icons/promo.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        threedmodeling: file(relativePath: {eq: "icons/3d-modeling.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        setting: file(relativePath: {eq: "icons/setting.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        computer: file(relativePath: {eq: "icons/computer.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        code: file(relativePath: {eq: "icons/code.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        agile: file(relativePath: {eq: "icons/agile.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        webdevelopment: file(relativePath: {eq: "icons/web-development.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }      
      `}
      render={({ 
        background,
        development,
        application,
        promo,
        threedmodeling,
        setting,
        computer,
        agile,
        code,
        webdevelopment}) => <ServicesOne  
        background={background}
        development ={development}
        application ={application}
        promo ={promo}
        threedmodeling ={threedmodeling}
        setting ={setting}
        computer ={computer}
        agile ={agile}
        code={code}
        webdevelopment ={webdevelopment}
        {...props} />}
    />
  )